import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/Layout'
import Header from '../components/Header'
import desktopIcon from '../assets/images/icons/desktop.svg'
import mobileIcon from '../assets/images/icons/mobile.svg'
import landscapeIcon from '../assets/images/icons/landscape.svg'
import desktopIconBlue from '../assets/images/icons/desktopBlue.svg'
import mobileIconBlue from '../assets/images/icons/mobileBlue.svg'
import landscapeIconBlue from '../assets/images/icons/landscapeBlue.svg'
import leftArrow from '../assets/images/icons/left_arrow.svg'
export default function Models({ data, pageContext }) {
  const { t } = useTranslation()
  const [preview, setPreview] = useState('desktop')

  return (
    <Layout className="home" locale={pageContext.language} template="home">
      <Header></Header>
      <main class="bg-grey">
        <div class="container">
          <div className="template-models">
            <div className="template-models__action">
              <div className="template-models__action--back font-semibold text-primary secondary">
                <Link to={'/models'}>
                  <img src={leftArrow} style={{ height: '15px' }} />{' '}
                  {t('page_models_back')}
                </Link>
              </div>
              <div className="template-models__action--btn">
                <Link to="/contact" className="btn btn-primary">
                  {t('page_models_btn')}
                </Link>
              </div>
              <div className="template-models__action--device">
                <ul>
                  <li>
                    <button onClick={() => setPreview('desktop')}>
                      {preview === 'desktop' ? (
                        <img src={desktopIconBlue} alt="desktop icon" />
                      ) : (
                        <img src={desktopIcon} alt="desktop icon" />
                      )}
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setPreview('mobile')}>
                      {preview === 'mobile' ? (
                        <img src={mobileIconBlue} alt="mobile icon" />
                      ) : (
                        <img src={mobileIcon} alt="mobile icon" />
                      )}
                    </button>
                  </li>
                  <li>
                    <button onClick={() => setPreview('landscape')}>
                      {preview === 'landscape' ? (
                        <img src={landscapeIconBlue} alt="landscape icon" />
                      ) : (
                        <img src={landscapeIcon} alt="landscape icon" />
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="template-models__preview">
              {preview === 'desktop' && data.models.desktop && (
                <div className="template-models__preview--desktop">
                  <div className="device">
                    <img src={data.models.desktop} alt="desktop" />
                  </div>
                  <p className="template-models__warning text-primary secondary">
                    {t('page_models_warning_laptop')}
                  </p>
                </div>
              )}
              {preview === 'mobile' && data.models.mobile && (
                <div className="template-models__preview--mobile">
                  <div className="device">
                    <img src={data.models.mobile} alt="mobile" />
                  </div>
                </div>
              )}
              {preview === 'landscape' && data.models.landscape && (
                <div className="template-models__preview--landscape">
                  <div className="device">
                    <img src={data.models.landscape} alt="landscape" />
                  </div>
                  <p className="template-models__warning text-primary secondary">
                    {t('page_models_warning_tablet')}
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* <Content models={models} /> */}
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    models(id: { eq: $id }) {
      name {
        en
      }
      desktop
      mobile
      landscape
    }
  }
`
